@font-face {
  font-family: 'Proxima Nova';
  font-weight: 300;
  font-style: normal;
  src: url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-light.woff2')
      format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-light.woff')
      format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-light.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 400;
  font-style: normal;
  src: url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-regular.woff2')
      format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-regular.woff')
      format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-regular.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 500;
  font-style: normal;
  src: url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-medium.woff2')
      format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-medium.woff')
      format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-medium.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 600;
  font-style: normal;
  src: url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-semibold.woff2')
      format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-semibold.woff')
      format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-semibold.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 700;
  font-style: normal;
  src: url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-bold.woff2')
      format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-bold.woff')
      format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-bold.ttf')
      format('truetype');
}
