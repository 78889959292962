$andes-theme: 'mercadolibre';

@import './proxima-nova';
@import '~@andes/common/index';
@import '~@andes/button/index';

html {
  scroll-behavior: smooth;
}

body {
  border-collapse: inherit;
}

.nav-header {
  background-color: #ffe600;
  height: 52px !important;
}

.nav-header::before {
  content: none;
}

header {
  height: 52px;
}

.nav-footer-primaryinfo {
  overflow: inherit;
}

.andes-button.login-button,
.andes-button.login-button:hover,
.andes-button.login-button:link,
.andes-button.login-button:visited,
.andes-button.login-button:focus {
  border-color: rgb(0 0 0 / 45%);
  background-color: transparent;
  color: rgb(0 0 0 / 45%);
  padding: 8px 15px;
}

.andes-button--small {
  font-size: 14px !important;
}

.nav-logo,
.nav-logo-no-cbt,
.nav-logo-no-cbt-pt {
  height: 39px;
  width: 201px;
  position: absolute;
  background-size: 95% 95%;
  background-size: 95%;
  background-repeat: no-repeat;
  top: 10px;
  left: 10px;
}

.nav-logo-gs {
  display: none;
}

@media (min-width: 1024px) {
  .nav-logo-wrapper {
    display: inline-block;

    .nav-logo {
      background-image: url('https://http2.mlstatic.com/static/org-img/mkt/email-mkt-assets/davinci/2x/logo-meli@2x.png');
      height: 29px;
      top: 11px;
      width: 134px;
    }

    .nav-logo-gs {
      display: block;
      color: rgb(0 0 0 / 80%);
      font-size: 18px;
      font-weight: 500;
      position: relative;
      top: 11px;
      padding-left: 16px;
      border-left: 1px solid rgb(51 51 51 / 20%);
    }
  }
}

@media (max-width: 1023px) {
  .nav-logo-wrapper {
    .nav-logo {
      background-image: url('https://http2.mlstatic.com/static/org-img/mkt/email-mkt-assets/2x/isologoml.png');
    }
  }
}

.text-footer {
  display: flex;
}

.text-copyright {
  width: 250px;
}

.login-nav {
  margin-left: 20px !important;
}

.nav-header-user {
  margin-left: 10px;
  margin-right: 10px;
  height: 100% !important;
}

.nav-header-menu {
  padding-top: 0;
}

.nav-header-user-active {
  color: rgb(0 0 0 / 80%) !important;
  border-bottom: 2px solid #3483fa;
}

.nav-header-user a {
  color: rgb(0 0 0 / 45%) !important;
}

.nav-header-user a:hover {
  color: rgb(0 0 0 / 100%) !important;
}

.nav-header-user-active a {
  color: rgb(0 0 0 / 100%) !important;
}

.nav-footer-copyright {
  margin-top: -10px;
}

.logos-footer {
  display: flex;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  height: 80px;
  border-top: 1px solid rgb(0 0 0 / 10%);
  line-height: 1;
  gap: 30px;
}

.logo-ml,
.logo-mp {
  background-repeat: no-repeat;
  background-size: contain;
  width: 120px;
  height: auto;
}

.logo-ml {
  content: url('https://http2.mlstatic.com/storage/cbt-statics/global-selling/logos/logo-ml.png');
}

.logo-mp {
  content: url('https://http2.mlstatic.com/storage/cbt-statics/global-selling/logos/logo-mp.png');
}

@media (min-device-pixel-ratio: 2) and (min-width: 1201px),
  (min-resolution: 192dpi) and (min-width: 1201px),
  (min-resolution: 2dppx) and (min-width: 1201px) {
  .nav-header .nav-bounds {
    padding: 0 0 0 220px;
    height: 54px;
    margin-left: 100px;
    margin-right: 70px;
  }

  .nav-bounds {
    max-width: initial;
  }

  .nav-local {
    margin-left: 10px;
    margin-right: 10px;
    height: 100% !important;
  }
}

@media (min-width: 481px) and (max-width: 1200px) {
  .nav-header .nav-bounds {
    margin-left: 42px;
    margin-right: 40px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .nav-footer-copyright {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 175px;
    font-size: 13px;
    width: auto;
  }

  .nav-logo,
  .nav-logo-no-cbt,
  .nav-logo-no-cbt-pt {
    background-repeat: no-repeat;
    height: 28px;
    overflow: hidden;
    text-indent: -999px;
    width: 39px;
    top: 11px;
    position: absolute;
    left: 10px;
    outline: 0;
  }

  .margin-footer-text {
    margin-bottom: 30px;
  }

  .margin-end-footer-text {
    margin-bottom: 30px;
    padding-left: 5px;
  }

  .text-footer {
    display: block;
  }
}
